import {Component, Inject, Input, OnChanges, Optional} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {BASE_URL} from '@aztrix/environment';
import {
  displayName,
  forwardLink$,
  isGroup,
  isMeProfile,
  isOfType,
  isProfile,
  isProposal,
} from '@aztrix/helpers';
import {ForwardType, Group, PageLanguage, Profile} from '@aztrix/models';
import {ProfileRepresentation, ProposalRepresentation} from '@aztrix/sdk';
import {TranslateService} from '@aztrix/translate';
import {first} from 'rxjs/operators';

@Component({
  selector: 'ax-forward-button',
  templateUrl: 'forward-button.component.html',
  styleUrls: ['forward-button.component.scss'],
})
export class ForwardButtonComponent implements OnChanges {
  @Input() subject: Profile | ProfileRepresentation | Group | ProposalRepresentation | PageLanguage;
  @Input() forwardType: ForwardType;
  @Input() url: string;

  _subjectLink? = '';

  constructor(
    private _sanitizer: DomSanitizer,
    private _translate: TranslateService,
    @Optional() @Inject(BASE_URL) private _baseUrl: string
  ) {}

  ngOnChanges() {
    if (this.url) {
      this._subjectLink = this.url;
    } else {
      forwardLink$(this.subject, this._baseUrl)
        .pipe(first())
        .subscribe((forwardLink) => {
          this._subjectLink = forwardLink;
        });
    }
  }

  forwardIcon(type: ForwardType | string): string | undefined {
    switch (type) {
      case ForwardType.QR:
        return 'qrcode';
      case ForwardType.EMAIL:
        return 'email-outline';
      case ForwardType.LINKEDIN:
      case ForwardType.WHATSAPP:
      case ForwardType.FACEBOOK:
      case ForwardType.TWITTER:
        return `brands:${type.toLowerCase()}`;
      case ForwardType.LINK:
        return type.toLowerCase();
      case ForwardType.EMBED:
        return 'code-tags';
      default:
        return undefined;
    }
  }

  shareLink(type: ForwardType | string): string | SafeUrl {
    switch (type) {
      case ForwardType.TWITTER:
        // eslint-disable-next-line max-len
        return `https://twitter.com/intent/tweet?text=${this._shareText} ${this._subjectLink}`.replace(
          / /g,
          '%20'
        );
      case ForwardType.WHATSAPP:
        return this._sanitizer.bypassSecurityTrustUrl(
          `whatsapp://send?text=${this._shareText} ${this._subjectLink}`
        );
      case ForwardType.FACEBOOK:
        return `https://www.facebook.com/sharer/sharer.php?u=${this._subjectLink?.replace(
          ':',
          '%3A'
        )}`;
      case ForwardType.LINKEDIN:
        return `https://www.linkedin.com/shareArticle?mini=true&url=${
          this._subjectLink
        }&title=${this._shareText.replace(/ /g, '%20')}`;

      case ForwardType.EMAIL:
        return `mailto:?body=${this._translate.instant('forward.email.body')} ${
          this._subjectLink
        }&subject=${this._shareText}`;
      default:
        return this._sanitizer.bypassSecurityTrustUrl('javascript:void(0)');
    }
  }

  linkTarget(type: ForwardType | string): string {
    return isOfType(
      {type: <ForwardType>type},
      ForwardType.VCARD,
      ForwardType.LINK,
      ForwardType.QR,
      ForwardType.WHATSAPP,
      ForwardType.EMBED
    )
      ? '_self'
      : '_blank';
  }

  socialColor(type: ForwardType | string): string {
    return isOfType(
      {type: <ForwardType>type},
      ForwardType.LINKEDIN,
      ForwardType.WHATSAPP,
      ForwardType.FACEBOOK,
      ForwardType.TWITTER,
      ForwardType.INSTAGRAM
    )
      ? type.toLowerCase()
      : 'primary';
  }

  private get _shareText(): string {
    if (isProfile(this.subject) && isMeProfile(this.subject)) {
      return this._translate.instant('forward.link.text.profile');
    }
    if (isProfile(this.subject)) {
      return this._translate.instant('forward.link.text.contact', {
        contact: displayName(this._translate, <Profile>this.subject),
      });
    }
    if (isGroup(this.subject)) {
      return this._translate.instant('forward.link.text.group', {
        group: displayName(this._translate, <Group>this.subject),
      });
    }
    if (isProposal(this.subject)) {
      return this._translate.instant('forward.link.text.proposal');
    }
    return this._translate.instant('forward.link.text.page');
  }
}
